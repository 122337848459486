// @flow

import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'

import './roadmap.scss'

export default function Roadmap() {
  return (
    <Layout useContainer={false} className="roadmap-page">
      <Helmet title="Roadmap - Mainframe - Decentralized Application Platform" />
      <div className="home-page">
        <div className="top-area container">
          <div className="header-container">
            <h1>Roadmap</h1>
            <h2>
              Milestones for bridging zero-collateral opportunity <br />
              with blockchain.
            </h2>
          </div>
        </div>
      </div>
      <section className="container milestone-container">
        <ul className="milestones">
          <li>
            <h2>Protocol education</h2>
            <p>
              Mainframe{"'"}s role is to help define the initial iterations of
              ecosystem infrastructure and guide the development of
              improvements. Starting with the Mainframe Credit Protocol,
              Mainframe will publish the first draft and work on a reference
              implementation. Once the protocol stabilizes, we will produce a
              series of educational videos that explain how it works to help
              onboard users.
            </p>
          </li>
          <li>
            <h2>Originate loans</h2>
            <p>
              Extending credit to the borrower number one is the first
              significant milestone for MFT utility and Mainframe{"'"}s
              sustainability. We will be testing a centralized reference
              implementation that allows for a quick feedback loop as we learn
              from a controlled environment. Getting incentives right for each
              participant is our focus.
            </p>
          </li>
          <li>
            <h2>Service payments from borrowers</h2>
            <p>
              Payments are the primary value creation activity of the Mainframe
              Credit Protocol. While there may be nuanced implementation
              details, this milestone is tightly coupled with loan origination.
            </p>
          </li>
          <li>
            <h2>Utilize MFT</h2>
            <p>
              As value is created, MFT is purchased on the open market and
              locked up for variable amounts of time. Marketplace fees, lender
              {"'"}s repayment preferences, MFT premium rewards, and discounts
              all utilize MFT directly.
            </p>
          </li>
          <li>
            <h2>Minimize trust</h2>
            <p>
              Having quick feedback loops and delivering a reference
              implementation in a relatively short period is desirable; it comes
              with trade-offs. We will grow our utilization as we iterate on the
              more centralized pieces of the ecosystem, replacing them with
              better trust minimized alternatives.
            </p>
          </li>
          <li>
            <h2>Establish governance</h2>
            <p>
              At the core of utilizing MFT is a governance system that aligns
              incentives and iterates on the protocol to take advantage of new
              information and possibility. Those with skin in the game will have
              the opportunity to lead and steer future improvements to our
              protocol. Our initial governance structure will lay the foundation
              for our decentralized autonomous organization.
            </p>
          </li>
        </ul>
      </section>
    </Layout>
  )
}
